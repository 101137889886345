import { useEffect } from "react";
import { navigate } from "gatsby";

const Page = () => {
  useEffect(() => {
    navigate("/univerzita/");
  }, []);

  return null;
};

export default Page;
